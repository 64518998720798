.login_bg {
  background-image: url('/public/images/login_bg.jpg');
  background-size: cover;
  height: calc(100vh);
}

.login_form {
  position: absolute;
  width: 50%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .login_bg {
    display: none;
  }

  .login_form_container {
    box-shadow: none !important;
    border: none !important;
  }

  .login_form {
    width: 100%;
  }
}

.login_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 450px;
  min-height: 60%;
  padding: 10% 0%;
  background: var(--white);
  border-color: var(--greylight);
  border-width: thin;
  box-shadow: 0 1px 5px 1px var(--greylight);
  border-style: solid;
  border-radius: 50px;
}

.login_form_container>span {
  font-weight: 600;
  font-size: large;
  padding-bottom: 2rem;
  color: var(--secondary);
}

.login_form_container>img {
  width: 40%;
  padding-bottom: 0.5rem;
}

#firebaseui-auth-container {
  min-height: 250px;
  min-width: 70%;
}

#firebaseui-auth-container>.mdl-shadow--2dp {
  box-shadow: none;
}